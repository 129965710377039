import Link from "next/link"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import LocalAtmIcon from "@material-ui/icons/LocalAtm"
import AppHead from "@/components/AppHead"
import Logo from "@/components/Logo"

export default function Index(): JSX.Element {
  return <>
    <AppHead title="Pipeline Pro Tools" enableCrispChat={false}></AppHead>
    <main className="w-screen h-screen flex flex-col items-center justify-center p-12">
      <div className="box-content w-3/4 sm:w-1/2 p-4 flex flex-col items-center">
        <Logo className="h-16"/>
        <span className="mt-4">
          {`Welcome to Pipeline Pro Tools! Pro Tools has all you need to automate a lead pipeline. Want to learn more?`}
          <a
            href="https://calendly.com/guerilla-realty/demo"
            target="_blank"
            rel="noreferer noopener"
            className="ml-4 text-ppt-blue font-display border-b-2 border-ppt-blue cursor-pointer"
          >
            {"Book a demo"}
          </a>
        </span>
      </div>
      <div className="w-full flex items-center justify-center mt-8">
        <a
          href={process.env.NEXT_PUBLIC_ACCOUNT_SIGNUP_PAGE_URL}
          className="flex flex-col items-center font-display text-xl box-shadow-subtle p-4 mx-8 mb-8 bg-ppt-blue-800 rounded hover-scale text-center text-ppt-success"
          target="_blank"
        >
          <LocalAtmIcon
            style={{ height: "6rem", width: "6rem" }}
          />
          {"Pricing"}
        </a>
        <Link href="/app">
          <a className="flex flex-col items-center font-display text-xl box-shadow-subtle p-4 mx-8 mb-8 bg-ppt-blue-800 rounded hover-scale text-center text-ppt-blue">
            <ExitToAppIcon
              style={{ height: "6rem", width: "6rem" }}
            />
            {"Login"}
          </a>
        </Link>
      </div>
    </main>
  </>
}